import moment from "moment";
import { IGraphData, IGraphContentData, IGraphDataGender, IGraphHeatmapData } from '../interfaces/types';
import { EStatisticsType, IAttendanceFilter, IGenderFilter, EStatisticsGender, EStatisticsGenderType, EStatisticsDateType, EStatisticsInterval } from '../../reducers/data';
import { t } from '@lingui/macro';

export function getRandomColors(cnt: number) {
    let colors: string[] = []

    if (cnt < 1) {
        return []
    }

    for (let index = 1; index <= cnt; index++) {
        let perc = ((index % 2) * 50) + 25;
        let perc2 = 100 - ( ((index + 1) % 2) * 40 )
        colors.push("hsl(" + (index * (360 / cnt) % 360) + "," + perc2 + "%," + perc + "%)")
    }

    return colors
}

export function getDaysBetweenDates(startDate: string, endDate: string) {
    let start = moment(startDate)
    let end = moment(endDate)

    let now = start.clone()
    let dates = []

    while (now.isSameOrBefore(end)) {
        dates.push(now.format('YYYY-MM-DD'))
        now.add(1, 'days')
    }

    return dates
}

export function getYearsBetweenDates(startDate: string, endDate: string) {
    let start = moment(startDate)
    let end = moment(endDate)

    let now = start.clone()
    let dates = []

    while (now.isSameOrBefore(end)) {
        dates.push(now.format('YYYY'))
        now.add(1, 'year')
    }

    return dates
}

export function getDateRangeTitle(startDate: string, endDate: string, interval: EStatisticsInterval, i18n: any) {
    let text = moment(startDate).format('DD. MM. YYYY')

    let days = moment(endDate).diff(moment(startDate), 'days')
    let minutes = moment(endDate).diff(moment(startDate), 'minutes')

    if (startDate !== endDate && (days !== 1)) {
        text += ' - ' + moment(endDate).format('DD. MM. YYYY')
    }

    if (days > 1 && days <= 25) {
        text = t`Týždeň` + ' ' + moment(startDate).week() + '-' + moment(startDate).year()
    }

    if (days >= 26 && days <= 35) {
        text = moment(startDate).format('MMMM') + ' ' + moment(startDate).year()
    }

    if (days >= 100) {
        text = moment(startDate).year().toString()
    }

    if (minutes === 15 || minutes === 30 || minutes === 60) {
        text = moment(startDate).format('DD. MM. YYYY HH:mm:ss') + ' - ' + moment(endDate).format('HH:mm:ss')
    }

    if(interval === EStatisticsInterval.custom) {
        text = moment(startDate).format('DD. MM. YYYY') + ' - ' + moment(endDate).format('DD. MM. YYYY')
    }

    return text
}

export function getFilterDateRangeTitle(startDate: string, endDate: string, i18n: any, filterType: EStatisticsDateType) {
    switch (filterType) {
        case EStatisticsDateType.day:
            return moment(startDate).format('DD. MM. YYYY')
        case EStatisticsDateType.month:
            return moment(startDate).format('MMMM') + ' ' + moment(startDate).year()
        case EStatisticsDateType.week:
            return t`Týždeň` + ' ' + moment(startDate).week() + '-' + moment(startDate).year()
        case EStatisticsDateType.year:
            return moment(startDate).year().toString()
        default:
            if (startDate === endDate) {
                return moment(startDate).format('DD. MM. YYYY')
            }
            return moment(startDate).format('DD. MM. YYYY') + ' - ' + moment(endDate).format('DD. MM. YYYY')
    }
}

export function parseGraphData(graphData: IGraphData[], i18n: any) {
    let retObject: IGraphContentData = {
        series: [],
        categories: []
    }

    graphData.forEach(g => {
        retObject.categories.push(g.name)
    })

    let serie: {
        name: string,
        data: number[]
    } = {
        name: t`Počet návštevníkov`,
        data: []
    }

    graphData.forEach(g => {
        g.data.forEach(d => {
            serie.data.push(Number(d.count))
        })
    })

    retObject.series = [serie]
    retObject.series = []

    graphData.forEach(g => {
        retObject.series.push({
            name: g.name,
            data: g.data.map(d => {
                return [
                    moment(d.start).local().valueOf(),
                    Number(d.count)
                ]
            })
        })
    })

    return retObject
}

export function parseHeatData(graphData: IGraphData[], i18n: any) {
    let retObject: IGraphHeatmapData = {
        series: [],
        categories: []
    }

    graphData.forEach(g => {
        retObject.categories.push(g.name)
    })

    let serie: {
        name: string,
        data: number[]
    } = {
        name: t`Počet návštevníkov`,
        data: []
    }

    graphData.forEach(g => {
        g.data.forEach(d => {
            serie.data.push(Number(d.count))
        })
    })

    // retObject.series = [serie]
    retObject.series = []

    graphData.forEach(g => {
        retObject.series.push({
            name: g.name,
            data: g.data.map(d => {
                return [
                    moment(d.start).local().format('HH:mm'),
                    Number(d.count)
                ]
            })
        })
    })

    return retObject
}

export function parseGraphDataAttendance(graphData: IGraphData[], filter: IAttendanceFilter, i18n: any) {
    let retObject: IGraphContentData = {
        series: [],
        categories: []
    }

    graphData.forEach(g => {
        retObject.categories.push(g.name)
    })

    let serie: {
        name: string,
        data: number[]
    } = {
        name: t`Počet návštevníkov`,
        data: []
    }

    graphData.forEach(g => {
        g.data.forEach(d => {
            serie.data.push(Number(d.count))
        })
    })

    retObject.series = [serie]

    if (filter.type === EStatisticsType.cake) {
        retObject.series = serie.data
    }

    if (filter.type === EStatisticsType.xy) {
        retObject.series = []

        graphData.forEach(g => {
            retObject.series.push({
                name: g.name,
                data: g.data.map(d => {
                    return [
                        moment(d.start).valueOf(),
                        Number(d.count)
                    ]
                })
            })
        })
    }

    return retObject
}

export function parseGraphDataGender(graphData: IGraphDataGender[], filter: IGenderFilter, i18n: any) {
    let retObject: IGraphContentData = {
        series: [],
        categories: []
    }

    graphData.forEach(g => {
        retObject.categories.push(g.name)
    })

    let serie: {
        name: string,
        data: number[]
    } = {
        name: t`Počet návštevníkov`,
        data: []
    }

    graphData.forEach(g => {
        g.data.forEach(d => {
            serie.data.push(Number(filter.genderType === EStatisticsGenderType.number?d.count:d.percentage))
        })
    })

    retObject.series = [serie]

    if (filter.type === EStatisticsType.column) {
        let mansData: number[] = []
        let womansData: number[] = []

        graphData.forEach(g => {
            //For multiple genders
            mansData = mansData.concat(g.data.filter(d => d.gender === 'M').map(d => Number(filter.genderType === EStatisticsGenderType.number?d.count:d.percentage)))
            womansData = womansData.concat(g.data.filter(d => d.gender === 'F').map(d => Number(filter.genderType === EStatisticsGenderType.number?d.count:d.percentage)))
        })
        
        retObject.series = []

        if(filter.gender && [EStatisticsGender.all, EStatisticsGender.man].includes(filter.gender)) {
            retObject.series.push({
                name: t`Muži`,
                data: mansData
            })
        }
        if(filter.gender && [EStatisticsGender.all, EStatisticsGender.woman].includes(filter.gender)) {
            retObject.series.push({
                name: t`Ženy`,
                data: womansData
            })
        }
    }

    if (filter.type === EStatisticsType.cake) {
        retObject.categories = [
            t`Muži`,
            t`Ženy`
        ]

        let mens = 0
        let womans = 0

        graphData.forEach(g => {
            g.data.forEach(d => {
                if(d.gender === 'M') {
                    mens += Number(d.count)
                } else {
                    womans += Number(d.count)
                }
            })
        })
        retObject.series = [mens, womans]
    }

    if (filter.type === EStatisticsType.xy) {
        retObject.series = []

        graphData.forEach(g => {
            //For multiple genders
            let mans = g.data.filter(d => d.gender === 'M')
            let womans = g.data.filter(d => d.gender === 'F')

            if(mans.length > 0 && filter.gender && [EStatisticsGender.all, EStatisticsGender.man].includes(filter.gender)) {
                retObject.series.push({
                    name: g.name + ' - ' + t`muži`,
                    data: mans.map(d => {
                        return [
                            moment(d.start).valueOf(),
                            Number(filter.genderType === EStatisticsGenderType.number?d.count:d.percentage)
                        ]
                    })
                })
            }

            if(womans.length > 0 && filter.gender && [EStatisticsGender.all, EStatisticsGender.woman].includes(filter.gender)) {
                retObject.series.push({
                    name: g.name + ' - ' + t`ženy`,
                    data: womans.map(d => {
                        return [
                            moment(d.start).valueOf(),
                            Number(filter.genderType === EStatisticsGenderType.number?d.count:d.percentage)
                        ]
                    })
                })
            }
        })
    }
    
    return retObject
}

export function numberWithSpaces(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export function numberToTime(x: number) {
    return new Date(x * 1000).toISOString().substr(11, 8);
}